import { BoxTick } from "iconsax-react";
import React from "react";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import HomepageTable from "./HomepageTable";

const HomepageBody = () => {
  return (
    <div className="main-body flex-1">
      <div className="w-full max-w-[1800px] 3xl:mx-auto">
        <div className="w-full px-4 flex flex-col gap-6 items-center justify-center mt-12 md:px-8 xl:px-12">
          <div className="flex gap-3 items-center justify-center border border-[#626168] px-2 py-2 rounded-xl sm:px-4">
            <div className="min-w-[1.75rem] min-h-[1.75rem] md:min-w-[1.5rem] md:min-h-[1.5rem]">
              <BoxTick className="text-white w-full h-full" />
            </div>

            <div className="text-white flex flex-col md:flex-row md:gap-1">
              <p className="text-sm sm:text-base">
                Announcements on Discord Server.{" "}
              </p>
              <p className="text-sm sm:text-base">
                <span className="text-[#A726C1] cursor-pointer hover:underline">
                  Click Here
                </span>{" "}
                to Join Our Discord Server
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-2 items-center text-center">
            <h1 className="text-white font-bold text-2xl sm:text-3xl">
              WARHAVEN INDEX PORTFOLIO
            </h1>

            <p className="text-[#9F9CA6] font-medium sm:text-lg">
              WARHAVEN INDEX PORTFOLIO AND THIS IS A RANDOM LAYER TEXT
            </p>
          </div>

          <Link to="/dashboard">
            <button className="bg-[#1E1E1E] mt-4 mb-8 text-white min-w-[200px] flex gap-4 items-center border border-[#626168] px-4 py-2 rounded-xl hover:border-[#A726C1] transition-colors duration-300">
              <HiChevronRight className="w-7 h-7" />
              <span>Dashboard</span>
            </button>
          </Link>
        </div>

        <HomepageTable />
      </div>
    </div>
  );
};

export default HomepageBody;

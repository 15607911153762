import React from "react";
import { HiChevronRight, HiOutlineStar } from "react-icons/hi";

const DashboardTableRow = () => {
  return (
    <tr className="border-b border-[#232530]">
      <td className="max-w-[1.5rem]">
        <div className="flex justify-between items-center gap-3">
          <HiOutlineStar className="w-5 h-5" />
          <span>1</span>
        </div>
      </td>

      <td>
        <div className="flex justify-start items-center gap-2 pr-6">
          <img
            src="https://s2.coinmarketcap.com/static/cloud/img/AllPortFolio.png?_=1042dbd"
            alt=""
            className="w-[30px] h-[30px]"
          />
          <span>Bitcoin BTC</span>
        </div>
      </td>

      <td className="text-center">₩1,502,989,963</td>

      <td className="text-center">₩1,502,989,963,439,782</td>

      <td className="flex justify-end">
        <button className="bg-[#1E1E1E] text-white min-w-[100px] flex items-center border border-[#626168] px-2 py-1.5 rounded-xl hover:border-[#A726C1] transition-colors duration-300">
          <HiChevronRight className="w-7 h-7" />
          <span>Claim</span>
        </button>
      </td>
    </tr>
  );
};

export default DashboardTableRow;

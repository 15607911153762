import { CloseSquare } from "iconsax-react";
import React from "react";
import { HiChevronRight } from "react-icons/hi";
import BitcoinIcon from "../../assets/bitcoin.png";

const PayoutOverlay = ({ onClose }) => {
  return (
    <div className="bg-[#00000050] z-40 w-full min-h-[100vh] fixed top-0 left-0 flex items-center justify-center">
      <div className="overlay-body relative w-fit bg-black rounded-2xl px-12 pt-16 pb-4 flex flex-col items-center gap-6 max-h-[85vh] overflow-y-scroll sm:px-8 md:px-12 md:py-12 md:max-h-none md:overflow-y-auto">
        <div className="relative text-white flex flex-col gap-6">
          <h1 className="text-2xl font-bold">Portfolio Payouts</h1>

          <div className="flex flex-col gap-4 sm:flex-row lg:gap-8">
            <div className="w-full bg-[#161616] p-2 rounded-md flex items-center gap-2 sm:w-[165px]">
              <div>
                <img src={BitcoinIcon} alt="" className="w-[45px] h-[45px]" />
              </div>

              <div className="flex flex-col gap-1">
                <p>BITCOIN</p>

                <div className="flex bg-black border border-[#626168] px-2 py-0.5 rounded-md">
                  <input
                    type="text"
                    placeholder="70"
                    name=""
                    id=""
                    className="bg-black outline-none text-[15px] w-[50px]"
                  />
                  <p>%</p>
                </div>
              </div>
            </div>

            <div className="w-full bg-[#161616] p-2 rounded-md flex items-center gap-2 sm:w-[165px]">
              <div>
                <img src={BitcoinIcon} alt="" className="w-[45px] h-[45px]" />
              </div>

              <div className=" flex flex-col gap-1">
                <p>BITCOIN</p>

                <div className="flex bg-black border border-[#626168] px-2 py-0.5 rounded-md">
                  <input
                    type="text"
                    placeholder="70"
                    name=""
                    id=""
                    className="bg-black outline-none text-[15px] w-[50px]"
                  />
                  <p>%</p>
                </div>
              </div>
            </div>

            <div className="w-full bg-[#161616] p-2 rounded-md flex items-center gap-2 sm:w-[165px]">
              <div>
                <img src={BitcoinIcon} alt="" className="w-[45px] h-[45px]" />
              </div>

              <div className="flex flex-col gap-1">
                <p>BITCOIN</p>

                <div className="flex bg-black border border-[#626168] px-2 py-0.5 rounded-md">
                  <input
                    type="text"
                    placeholder="70"
                    name=""
                    id=""
                    className="bg-black outline-none text-[15px] w-[50px]"
                  />
                  <p>%</p>
                </div>
              </div>
            </div>
          </div>

          <button className="bg-[#1E1E1E] mt-4 text-white w-[130px] flex items-center border border-[#626168] px-2 py-1.5 rounded-xl hover:border-[#A726C1] transition-colors duration-300">
            <HiChevronRight className="w-7 h-7" />
            <span>Save</span>
          </button>
        </div>

        <button onClick={onClose}>
          <CloseSquare className="w-6 h-6 text-white absolute top-4 right-4 transition-all hover:text-[#A726C1]" />
        </button>
      </div>
    </div>
  );
};

export default PayoutOverlay;

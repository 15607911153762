import React from "react";
import { HiInformationCircle, HiOutlineStar } from "react-icons/hi";
import Chart from "../../assets/chart.png";

const HomepageTableRow = () => {
  return (
    <tr className="border-b border-[#232530]">
      <td className="max-w-[1.5rem]">
        <div className="flex justify-between items-center gap-3">
          <HiOutlineStar className="w-5 h-5" />
          <span>1</span>
        </div>
      </td>

      <td>
        <div className="flex justify-start items-center gap-2 pr-6">
          <img
            src="https://s2.coinmarketcap.com/static/cloud/img/AllPortFolio.png?_=1042dbd"
            alt=""
            className="w-[30px] h-[30px]"
          />
          <span>Bitcoin BTC</span>
        </div>
      </td>

      <td>₩1,502,989,963</td>

      <td>₩1,502,989,963,439,782</td>

      <td>
        <div className="text-end flex flex-col gap-1">
          <p>₩51,502,989,963,439</p>
          <p className="text-[#ffffff50]">932,071 BTC</p>
        </div>
      </td>

      <td>
        <div className="flex items-center justify-end gap-1">
          <HiInformationCircle className="w-5 h-5" />
          <span>18,648,248 BTC</span>
        </div>
      </td>

      <td>
        <div className="flex justify-end">
          <img src={Chart} alt="" className="min-w-[150px]" />
        </div>
      </td>
    </tr>
  );
};

export default HomepageTableRow;

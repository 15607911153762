import React from "react";
import HomepageBody from "../components/Homepage/HomepageBody";
import Header from "../components/common/Header";

const Homepage = () => {
  return (
    <div className="relative min-h-[100vh] flex flex-col">
      <Header />

      <HomepageBody />
    </div>
  );
};

export default Homepage;

import { BoxTick } from "iconsax-react";
import React from "react";
import Logo from "../../assets/logo.png";

const Header = () => {
  return (
    <header className="relative bg-[#0D1421] after:bg-[#323546] after:absolute after:w-full after:h-[1px] after:top-12">
      <div className="w-full max-w-[1800px] 3xl:mx-auto">
        <div className="w-[full] whitespace-nowrap px-4 py-3 flex gap-2 items-center justify-center">
          <div className="min-w-[1.25rem] min-h-[1.25rem] sm:w-6 sm:h-6">
            <BoxTick className="text-white w-full h-full" />
          </div>

          <p className="announcement text-white text-sm overflow-x-scroll">
            Announcements on Discord Server.{" "}
            <span className="text-[#A726C1] cursor-pointer hover:underline">Click Here</span> to
            Join Our Discord Server
          </p>
        </div>

        <div className="p-4 flex justify-between md:px-8 xl:px-12">
          <div>
            <img src={Logo} alt="" />
          </div>

          <div>
            <button className="bg-[#2c2d31] text-white px-4 py-1.5 rounded-lg">
              Ethereum
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import { BinanceUsd, Blur, BoxTick } from "iconsax-react";
import React, { useState } from "react";
import { HiChevronRight, HiPencilAlt } from "react-icons/hi";
import btc from "../../assets/btc.png";
import eth from "../../assets/eth.png";
import usdt from "../../assets/usdt.png";
import DashboardTable from "./DashboardTable";
import DepositOverlay from "./DepositOverlay";
import PayoutOverlay from "./PayoutOverlay";

const DashboardBody = () => {
  const [isDepositOverlayOpen, setDepositOverlayOpen] = useState(false);
  const [isPayoutOverlayOpen, setPayoutOverlayOpen] = useState(false);
  const overlayPercentage = 30;

  const handleDepositClick = () => {
    setDepositOverlayOpen(true);
  };

  const handlePayoutClick = () => {
    setPayoutOverlayOpen(true);
  };

  return (
    <div>
      <div className="relative main-body min-h-[100vh] flex-1 lg:flex lg:justify-center">
        <div className="w-full max-w-[1800px] px-8 pb-8 md:px-16 lg:w-fit 3xl:mx-auto">
          <div className="flex flex-col items-center gap-4 pt-12 lg:flex-row lg:justify-start lg:gap-12 lg:pt-16 lg:pb-8">
            <div className="relative flex justify-center">
              <div className="relative text-center w-auto">
                <div>
                  <img
                    src="https://scontent.fktm19-1.fna.fbcdn.net/v/t39.30808-6/309861096_10160243765382713_7380568418544153113_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=e3f864&_nc_ohc=iW1GPkpMwM0AX_z0iFW&_nc_ht=scontent.fktm19-1.fna&oh=00_AfBoIIVkvOrS9FqBeYRzDmm1dEy6XN-HC9HUvyoJl0VMpg&oe=648C695A"
                    alt=""
                    className="rounded-lg border border-[#5F5F5F] w-[85vw] h-[85vw] max-h-[420px] max-w-[350px]"
                  />

                  <div className="absolute top-0 left-0">
                    <img
                      src="https://scontent.fktm19-1.fna.fbcdn.net/v/t39.30808-6/309861096_10160243765382713_7380568418544153113_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=e3f864&_nc_ohc=iW1GPkpMwM0AX_z0iFW&_nc_ht=scontent.fktm19-1.fna&oh=00_AfBoIIVkvOrS9FqBeYRzDmm1dEy6XN-HC9HUvyoJl0VMpg&oe=648C695A"
                      alt=""
                      className="rounded-lg border grayscale border-[#5F5F5F] w-[85vw] h-[85vw] max-h-[420px] max-w-[350px]"
                      style={{ clipPath: `inset(0 0 0 ${overlayPercentage}%)` }}
                    />
                  </div>
                </div>

                <div className="absolute w-full top-[15%] px-3 flex flex-col gap-1 items-center justify-center">
                  <p className="text-[#626168] text-sm font-medium">
                    Mint One Of Three Tiers
                  </p>
                  <h1 className="text-white font-semibold text-2xl">
                    WARHAVEN
                  </h1>
                </div>

                <div className="w-full flex justify-center">
                  <div className="bg-[#1E1E1E] px-4 py-2 rounded-xl border border-[#626168] absolute bottom-[3%] text-white w-[95%] flex items-center gap-2">
                    <BoxTick className="w-5 h-5" />
                    <p className="truncate flex-1 text-sm">
                      Referral Link : https://warhaven.com/?addr=0x0000000000
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative text-white self-start w-full my-4 md:self-auto lg:my-0 lg:w-auto">
              <div className="flex flex-col gap-2">
                <div className="bg-[#626168] px-4 py-1.5 w-fit rounded-xl font-medium">
                  #122
                </div>
                <p className="text-lg">War Haven</p>
                <h1 className="text-2xl font-bold mt-2">War Haven NFT</h1>
              </div>

              <div className="mt-2">
                <p className="text-[#626168] text-lg font-medium">Stats</p>

                <div className="w-full grid grid-cols-1 gap-y-4 gap-x-8 border border-[#626168] p-6 my-2 rounded-xl sm:grid-cols-2">
                  <div className="flex items-center gap-2">
                    <Blur className="w-6 h-6 text-[#00FCCE]" />
                    <p>500$ Claimable Profits</p>
                  </div>

                  <div className="flex items-center gap-2">
                    <BinanceUsd className="w-6 h-6 text-[#00FCCE]" />
                    <p>500$ Claimable Profits</p>
                  </div>

                  <div className="flex items-center gap-2">
                    <BinanceUsd className="w-6 h-6 text-[#00FCCE]" />
                    <p>500$ Claimable Profits</p>
                  </div>

                  <div className="flex items-center gap-2">
                    <BinanceUsd className="w-6 h-6 text-[#00FCCE]" />
                    <p>500$ Claimable Profits</p>
                  </div>

                  <div className="flex items-center gap-2">
                    <BinanceUsd className="w-6 h-6 text-[#00FCCE]" />
                    <p>500$ Claimable Profits</p>
                  </div>
                </div>

                <div>
                  <p className="text-[#626168] text-lg font-medium">
                    Claimable In
                  </p>

                  <div className="flex items-center gap-3 my-2">
                    <img src={btc} alt="" className="cursor-pointer w-8 h-8" />
                    <img src={eth} alt="" className="cursor-pointer w-8 h-8" />
                    <img src={usdt} alt="" className="cursor-pointer w-8 h-8" />
                  </div>
                </div>

                <button
                  className="bg-[#1E1E1E] mt-4 mb-8 text-white min-w-[200px] flex gap-4 items-center border border-[#626168] px-2 py-1.5 rounded-xl hover:border-[#A726C1] transition-colors duration-300 lg:mb-0"
                  onClick={handleDepositClick}
                >
                  <HiChevronRight className="w-7 h-7" />
                  <span>DEPOSIT NOW</span>
                </button>
              </div>
            </div>
          </div>

          <div className="text-white mt-4">
            <div className="flex items-center gap-2">
              <h1 className="text-2xl font-bold">Your Portfolio</h1>
              <button onClick={handlePayoutClick}>
                <HiPencilAlt className="w-6 h-6 transition-all hover:text-[#A726C1]" />
              </button>
            </div>

            <DashboardTable />
          </div>
        </div>
      </div>

      {isDepositOverlayOpen && (
        <DepositOverlay onClose={() => setDepositOverlayOpen(false)} />
      )}

      {isPayoutOverlayOpen && (
        <PayoutOverlay onClose={() => setPayoutOverlayOpen(false)} />
      )}
    </div>
  );
};

export default DashboardBody;

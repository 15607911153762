import React from "react";
import DashboardBody from "../components/Dashboard/DashboardBody";
import Header from "../components/common/Header";

const Dashboard = () => {
  return (
    <div className="relative min-h-[100vh] flex flex-col">
      <Header />

      <DashboardBody />
    </div>
  );
};

export default Dashboard;

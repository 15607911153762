import React from "react";
import { HiChevronUp } from "react-icons/hi";
import HomepageTableRow from "./HomepageTableRow";

const HomepageTable = () => {
  return (
    <div className="bg-[#17171A] overflow-x-auto whitespace-nowrap mx-4 mt-4 mb-8 md:mx-8 xl:mx-20">
      <table className="w-full text-white text-end border-spacing-4">
        <thead className="border-b border-[#232530]">
          <tr>
            <th className="flex justify-end items-center gap-1">
              <span>#</span>
              <HiChevronUp className="w-6 h-6" />
            </th>
            <th className="text-start">Currency</th>
            <th className="text-end">Price</th>
            <th>Market Cap</th>
            <th>Volume</th>
            <th>Circulating Supply</th>
            <th>Chart</th>
          </tr>
        </thead>

        <tbody className="text-white">
          <HomepageTableRow />
          <HomepageTableRow />
          <HomepageTableRow />
        </tbody>
      </table>
    </div>
  );
};

export default HomepageTable;

import React from "react";
import { HiChevronUp } from "react-icons/hi";
import DashboardTableRow from "./DashboardTableRow";

const DashboardTable = () => {
  return (
    <div className="bg-[#17171A] overflow-x-auto whitespace-nowrap mt-8">
      <table className="w-full text-white text-end border-spacing-4">
        <thead className="border-b border-[#232530]">
          <tr>
            <th className="flex justify-end items-center gap-1">
              <span>#</span>
              <HiChevronUp className="w-6 h-6" />
            </th>
            <th className="text-start">Currency</th>
            <th className="text-center">HOLDING</th>
            <th className="text-center">APR</th>
            <th>Claim</th>
          </tr>
        </thead>

        <tbody className="text-white">
          <DashboardTableRow />
          <DashboardTableRow />
          <DashboardTableRow />
        </tbody>
      </table>
    </div>
  );
};

export default DashboardTable;

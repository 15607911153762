import { Blur, CloseSquare } from "iconsax-react";
import React, { useState } from "react";

const DepositOverlay = ({ onClose }) => {
  const [activeButton, setActiveButton] = useState("paper");

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  return (
    <div className="bg-[#00000050] z-40 w-full min-h-[100vh] fixed top-0 left-0 flex items-center justify-center">
      <div className="overlay-body relative w-fit bg-black rounded-2xl px-8 pt-16 pb-4 flex flex-col items-center gap-6 max-h-[85vh] overflow-y-scroll md:px-12 md:py-12 md:max-h-none md:overflow-y-auto md:flex-row md:gap-10">
        <div className="flex justify-center">
          <img
            src="https://scontent.fktm19-1.fna.fbcdn.net/v/t39.30808-6/309861096_10160243765382713_7380568418544153113_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=e3f864&_nc_ohc=iW1GPkpMwM0AX_z0iFW&_nc_ht=scontent.fktm19-1.fna&oh=00_AfBoIIVkvOrS9FqBeYRzDmm1dEy6XN-HC9HUvyoJl0VMpg&oe=648C695A"
            // src={Rectangle1}
            alt=""
            className="object-cover rounded-lg border border-[#5F5F5F] max-w-[200px] h-[235px] sm:max-w-[250px] sm:h-[294px] md:max-w-[300px] md:h-[350px]"
          />
        </div>

        <div className="text-white flex flex-col gap-5 lg:mr-16">
          <h1 className="text-xl font-bold">DEPOSIT</h1>

          <div className="border border-white rounded-md px-2 py-1.5 flex items-center gap-1 text-center w-fit text-sm">
            <button
              className={`${
                activeButton === "paper" ? "bg-[#303030]" : ""
              } px-2 py-0.5 rounded-md`}
              onClick={() => handleButtonClick("paper")}
            >
              PAPER
            </button>
            <button
              className={`${
                activeButton === "web3" ? "bg-[#303030]" : ""
              } px-2 py-0.5 rounded-md`}
              onClick={() => handleButtonClick("web3")}
            >
              WEB3
            </button>
          </div>

          <div className="border border-white rounded-md px-2 py-1 flex items-center gap-2">
            <p className="text-lg font-medium">$</p>

            <input
              type="text"
              placeholder="100"
              className="outline-none bg-black text-[15px] w-[180px]"
            />
          </div>

          <div className="flex flex-col gap-2 text-sm">
            <div className="flex items-center gap-2 sm:gap-4">
              <Blur className="w-6 h-6 text-[#00FCCE]" />
              <p>Fees : $0</p>
            </div>

            <div className="flex items-center gap-2 sm:gap-4">
              <Blur className="w-6 h-6 text-[#00FCCE]" />
              <p>Pool Value : $0</p>
            </div>

            <div className="flex items-center gap-2 sm:gap-4">
              <Blur className="w-6 h-6 text-[#00FCCE]" />
              <p>Tier Change : 1</p>
            </div>
          </div>

          <div className="flex flex-col gap-2.5">
            <button className="border border-white rounded-md py-1 text-[15px] hover:border-[#A726C1] transition-colors duration-300">
              APPROVE
            </button>
            <button className="border border-white rounded-md py-1 text-[15px] hover:border-[#A726C1] transition-colors duration-300">
              DEPOSIT
            </button>
          </div>
        </div>

        <button onClick={onClose}>
          <CloseSquare className="w-6 h-6 text-white absolute top-4 right-4 transition-all hover:text-[#A726C1]" />
        </button>
      </div>
    </div>
  );
};

export default DepositOverlay;
